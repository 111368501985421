.user-clips {
    margin-top: 40px; /* Add spacing below the banner */
    padding: 20px;
    background: rgba(0, 0, 0, 0.7); /* Slightly darker background */
    border-radius: 8px;
    width: 90%;
    margin: 0 auto; /* Center horizontally */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  }
  
  .clips-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the grid */
    gap: 20px; /* Spacing between clips */
  }
  
  .clip-card {
    border: 1px solid #444; /* Darker border */
    border-radius: 8px;
    padding: 15px;
    width: 300px; /* Reduce card width */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    background: #222; /* Darker background for contrast */
    color: #fff; /* White text for readability */
    overflow: hidden; /* Prevent overflow issues */
  }
  
  .clip-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .clip-card video {
    width: 100%;
    border-radius: 4px;
  }
  
  .clip-card p {
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 5px 0;
  }

  .no-clips {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .header-container {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    margin-bottom: 20px; 
  }


  